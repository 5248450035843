import React, {Component} from 'react';
import classes from "./CardTeam.css";
import {clsAllStyle} from "../../libs/Styles";

const clsStyle = {
    div__card: ["card", classes.div__card],
    img__photo: [classes.img__photo],
    p__text_name: [classes.p__text_name],
    div__col_4: ["col-4", classes.div__col_4],
    div__info_person:[classes.div__info_person]
}

class CardTeam extends Component {
    render() {
        return (
            <div className={clsStyle.div__card.join(" ")}>
                <div className="row">
                    <div className={clsStyle.div__col_4.join(" ")}>
                        <img className={clsStyle.img__photo.join(" ")} src={this.props.person.photo} alt=""/>
                    </div>
                    <div className="col-8">
                        <div className={clsStyle.div__info_person.join(" ")}>
                            <p className={clsStyle.p__text_name.join(" ")}>{this.props.person.name}</p>
                            <p>{this.props.person.post}</p>
                        </div>
                    </div>
                </div>
                <ul className={clsAllStyle.ul__content.join(" ")}>
                    {
                        this.props.person.regal.map((item, index) => {
                            return(
                                <li key={index}><span>{item}</span></li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default CardTeam;