import React, {Component} from 'react';
import logo from "../../materials/zetta_logo_big.png";
import logo_color from "../../materials/logo_color.png"
import classes from "./Topic.css";
import {clsAllStyle} from "../../libs/Styles";

const clsStyle = {
    div__content: [classes.div__content],
    div__img_content: [classes.div__img_content],
    div__img_logo: [classes.div__img_logo],
    img_content: [classes.img_content],
    img__logo: [classes.img__logo],
    p__text_content:[classes.p__text_content],
    div__img_content_none:[classes.div__img_content_none]
}

class Topic extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <div className={clsStyle.div__content.join(" ")}>
                <div className={clsStyle.div__img_content.join(" ") + " " + clsStyle.div__img_logo.join(" ")}>
                    <img className={clsStyle.img_content.join(" ") + " " + clsStyle.img__logo.join(" ")} src={logo} alt="logo"/>
                    {/*<p className={clsStyle.p__text_content.join(" ")}>ООО&nbsp;«ЗЕТА&nbsp;КОНСАЛТИНГ»</p>*/}
                </div>
                <div className={clsStyle.div__img_content.join(" ") + " " + clsStyle.div__img_content_none.join(" ")}>
                    <img className={clsStyle.img_content.join(" ")} src={logo_color} alt="logo"/>
                </div>
                </div>
            </div>
        );
    }
}

export default Topic;