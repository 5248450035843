import React, {Component} from 'react';
import {clsAllStyle} from "../../libs/Styles";
import Header from "../../components/Header/Header";
import classes from "./Team.css";
import img_test from "../../materials/imgs/test_photo.jpg";
import logo from "../../materials/zetta_logo_big.png"
import CardTeam from "../../components/CardTeam/CardTeam";
import {team} from "../../libs/Main";

const clsStyle = {
    div__content: ["container", classes.div__content],
    div__img_logo: [classes.div__img_logo]
}

class Team extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name={"Ключевая команда"} section={"team"}/>
                <div className={clsStyle.div__content.join(" ")}>
                    {
                        team.map((person, index) => {
                            return(
                                <CardTeam key={index} person={person}/>
                            )
                        })
                    }
                    <div className={clsStyle.div__img_logo.join(" ")}>
                        <img src={logo} alt=""/>
                    </div>
                </div>

            </div>
        );
    }
}

export default Team;