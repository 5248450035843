import React from 'react';
import classes from './Footer.css';
import logo from "../../../materials/zetta_logo_big.png";

const clsStyle = {
    div__container_fluid: ["container-fluid"],
    a__nav_link_dev: ["nav-link", classes.a__nav_link_dev],
    a__nav_link_copy: ["nav-link", classes.a__nav_link_copy],
    div__copy:[classes.div__copy]
}

function Footer() {
    return (
        <nav className="navbar sticky-bottom navbar-light bg-light">
            <div className={clsStyle.div__container_fluid.join(" ")}>
                <a className={clsStyle.a__nav_link_dev.join(" ")} href="https://t.me/sanboroda" target="_blank">BRD Production</a>
                <div className={clsStyle.div__copy.join(" ")}>
                    <a className={clsStyle.a__nav_link_copy.join(" ")} href="#">&copy; ООО «Зета Консалтинг», 2021-{new Date().getFullYear()}</a>
                    <p className={clsStyle.a__nav_link_copy.join(" ")}>Все права защищены</p>
                </div>
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="logo" height="24"/>
                </a>
            </div>
        </nav>
    );
}

export default Footer;