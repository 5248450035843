import React, {Component} from 'react';
import {clsAllStyle} from "../../libs/Styles";
import {projects} from "../../libs/Main";
import Header from "../../components/Header/Header";
import classes from "./Projects.css";

const clsStyle = {
    thead__content: [classes.thead__content],
    th__1: [classes.th__1],
    td__1: [classes.td__1],
}


class Projects extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name={"Ключевые проекты 2021 года"} section={"projects"}/>
                <table className="table table-hover">
                    <thead className={clsStyle.thead__content.join(" ")}>
                    <tr>
                        <th className={clsStyle.th__1.join(" ")} scope="col">Фед. округ</th>
                        <th scope="col">Наименование проекта</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        projects.map((project, index) => {
                            return(
                                <tr key={index}>
                                    <td className={clsStyle.td__1.join(" ")}><span>{project.district}</span></td>
                                    <td>{project.name}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Projects;