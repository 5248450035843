import React, {Component} from 'react';
import classes from './Services.css';
import {clsAllStyle} from "../../libs/Styles";
import Header from "../../components/Header/Header";
import img_business_idea from "../../materials/imgs/business_idea_img.png";
import img_investment_phase from "../../materials/imgs/investment_phase_img.png";
import img_working_business from "../../materials/imgs/working_business_img.png";

const clsStyle = {
    div__col_sm: [classes.div__col_sm],
    div__text_stage: [classes.div__text_stage],
    div__colors: {
        div__color_1: [classes.div__color_1],
        div__color_2: [classes.div__color_2],
        div__color_3: [classes.div__color_3],
    },
    p__color_white: [classes.p__color_white]
}

class Services extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name="Услуги компании" section={"services"}/>
                <div className="container">
                    <div className="row">
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <img src={img_business_idea} alt=""/>
                            <div className={clsStyle.div__text_stage.join(" ") + " " + clsStyle.div__colors.div__color_1.join(" ")}>
                                <p>Бизнес-идея</p>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Маркетинговые исследования</span></li>
                                <li><span>Научно-исследовательская поддержка</span></li>
                                <li><span>Предварительная техническая концепция</span></li>
                                <li><span>Подбор технологий & оборудования</span></li>
                                <li><span>Организация взаимодействия с возможными поставщиками</span></li>
                                <li><span>Финансовое моделирование</span></li>
                                <li><span>Проработка опций по привлечению финансирования</span></li>
                            </ul>
                        </div>
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <img src={img_investment_phase} alt=""/>
                            <div className={clsStyle.div__text_stage.join(" ") + " " + clsStyle.div__colors.div__color_2.join(" ")}>
                                <p className={clsStyle.p__color_white.join(" ")}>Инвестиционная фаза</p>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Технико-экономическое обоснование инвестиционного проекта</span></li>
                                <li><span>Интеграция мер государственной поддержки</span></li>
                                <li><span>Разработка проектной & рабочей документации</span></li>
                                <li><span>Сопровождение экспертизы проекта</span></li>
                                <li><span>Проектное управление</span></li>
                                <li><span>Структурирование сделки и привлечение финансирования</span></li>
                            </ul>
                        </div>
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <img src={img_working_business} alt=""/>
                            <div className={clsStyle.div__text_stage.join(" ") + " " + clsStyle.div__colors.div__color_3.join(" ")}>
                                <p className={clsStyle.p__color_white.join(" ")}>Работающий бизнес</p>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Стратегия/концепция развития</span></li>
                                <li><span>Трансформация/модернизация бизнеса</span></li>
                                <li><span>Комплексный аудит/due diligence (финансовый, налоговый, юридический, технический и технологический)</span></li>
                                <li><span>Оптимизация и внедрение технологических процессов</span></li>
                                <li><span>Оптимизация операционных затрат/услуги по сопровождению внедрения предложений</span></li>
                                <li><span>Цифровая трансформация: от концепции до внедрения</span></li>
                                <li><span>ESG-политика компании: от концепции до внедрения</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;