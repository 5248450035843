import './App.css';
import React, {Component} from "react";
import Topic from "./containers/Topic/Topic";
import Company from "./containers/Company/Company";
import Competencies from "./containers/Competencies/Competencies"
import Contacts from "./containers/Contacts/Contacts";
import Management from "./containers/Management/Management";
import Projects from "./containers/Projects/Projects";
import Services from "./containers/Services/Services";
import Team from "./containers/Team/Team";
import NavBar from "./components/Navigation/NavBar/NavBar";
import Footer from "./components/Navigation/Footer/Footer";



class App extends Component {
    render() {
        return (
            <>
                <NavBar/>
                <Topic/>
                <Company/>
                <Competencies/>
                <Services/>
                <Management/>
                <Team/>
                <Projects/>
                <Contacts/>
                {/*<Layout/>*/}
                <Footer/>
            </>
        );
    }
}

export default App;
