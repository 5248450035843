import img_test from "../materials/imgs/test_photo.jpg";
import img_KogemjakoN from "../materials/imgs/team/KogemjakoN.jpg";
import img_MineevA from "../materials/imgs/team/MineevA.jpg";
import img_HvanV from "../materials/imgs/team/HvanV.jpg";
import img_KomarovA from "../materials/imgs/team/KomarovA.jpg";
import img_LaninaM from "../materials/imgs/team/LaninaM.jpg";
import img_MorozovaE from "../materials/imgs/team/MorozovaE.jpg";


// eslint-disable-next-line no-unused-vars
export const links = [
    {to: "company", label:"О Компаниии", exact: true, active: true},
    {to: "competencies", label:"Компетенции", exact: false, active: false},
    {to: "services", label:"Услуги", exact: false, active: false},
    {to: "management", label:"Руководство", exact: false, active: false},
    {to: "team", label:"Команда", exact: false, active: false},
    {to: "projects", label:"Проекты", exact: false, active: false},
    {to: "contacts", label:"Контакты", exact: false, active: false},

]

export const team = [
    // {
    //     name: "Николай Кожемяко", post: "Эксперт по стратегическому развитию", photo: img_KogemjakoN,
    //     regal: [
    //         "Стратегическое развитие предприятий ЛПК, разработка ПИП, государственно-частное партнерство, оценка и управление инвестиционной привлекательностью компаний, ВЭД",
    //         "Образование – высшее техническое, д.э.н.",
    //         "Опыт работы – 20 лет",
    //     ],
    // },
    {
        name: "Алексей Минеев", post: "Эксперт в области экономики и финансов", photo: img_MineevA,
        regal: [
            "Разработка финансовых моделей, анализ экономической эффективности инвестиционных проектов",
            "Образование — высшее техническое, высшее экономическое (прямые инвестиции, международный менеджмент)",
            "Опыт работы – 25 лет",
        ],
    },
    {
        name: "Елена Морозова", post: "Эксперт в области маркетинга", photo: img_MorozovaE,
        regal: [
            "Проведение аналитических исследований рынков, построение прогнозов согласно различным сценариям",
            "Образование — высшее экономическое",
            "Опыт работы – более 10 лет",
        ],
    },
    {
        name: "Александр Комаров", post: "Эксперт в области проектирования", photo: img_KomarovA,
        regal: [
            "Анализ и сбор исходно-разрешительной документации, проектно-изыскательские работы, экспертиза документации",
            "Образование — высшее техническое",
            "Опыт работы более 12 лет, в том числе на позиции главного инженера проекта на объектах промышленности",
        ],
    },
    {
        name: "Маргарита Ланина", post: "Консультант-аналитик", photo: img_LaninaM,
        regal: [
            "Анализ исходных данных, копирайтинг технической, инженерной, коммерческой и финансовой документации",
            "Переводы технической и коммерческой документации",
            "Образование – высшее техническое, высшее филологическое",
            "Опыт работы в консалтинге – 10 лет",
        ],
    },
    {
        name: "Виктория Хван", post: "Бизнес-ассистент, координатор проектов", photo: img_HvanV,
        regal: [
            "Координация и контроль выполнения работ по проектам, копирайтинг",
            "Образование — высшее экономическое (международный менеджмент)",
            "Опыт работы – 2 года",
        ],
    },
]

export const projects = [
    {district: "СФО", name:"Разработка финансовой модели и выполнение технического и технологического аудита проекта строительства лесоперерабатывающего комплекса (гринфилд) мощностью 325 000 м3 готовой продукции в год (лесозаготовка, производство пиломатериалов)", year:"2021", active: true},
    {district: "СЗФО", name:"Выполнение технологического аудита существующего предприятия по выпуску OSB-плит мощностью 300 000 м3 готовой продукции в год, оценка бюджета и графика развития", year:"2021", active: true},
    {district: "СЗФО", name:"Проведение маркетингового исследования рынка, разработка финансовой модели и выполнение технического и технологического аудита существующего лесопильного производства и проекта по строительству нового лесопильного завода мощностью 250 000 м3 готовой продукции в год", year:"2021", active: true},
    {district: "СЗФО", name:"Разработка предварительного ТЭО проекта «Строительство завода по производству сухих пиломатериалов мощностью 250 000 м3 готовой продукции в год, а также пеллетного производства для утилизации отходов»", year:"2021", active: true},
    {district: "УФО", name:"Проведение маркетингового исследования рынка, проведение финансового, налогового и юридического DD проекта, разработка финансовой модели и выполнение технического и технологического аудита лесопромышленного холдинга (лесозаготовка, производство пиломатериалов, производство плитной продукции)", year:"2021", active: true},
    {district: "СЗФО", name:"Проведение маркетингового исследования рынка и разработка верхнеуровневой стратегии продаж (целлюлозно-бумажное производство)", year:"2021", active: true},
    {district: "СФО", name:"Проведение маркетингового исследования рынка, разработка финансовой модели и выполнение технического и технологического аудита производства мощностью 60 000 м3 в год (деревянные панели)", year:"2021", active: true},
    {district: "СЗФО", name:"Проведение маркетингового исследования рынка, разработка финансовой модели и выполнение технического и технологического аудита производства (газохимия)", year:"2021", active: true},
    {district: "СЗФО", name:"Разработка плана по приобретению парка техники для обеспечения лесозаготовительного производства с расчетной лесосекой 1 млн м3, разработка финансовой модели", year:"2021", active: true},
    {district: "СЗФО", name:"Проведение маркетингового исследования рынка, выполнение технического и технологического аудита проекта по строительству нового лесопильного завода 50 000 м3 и пеллетной линии 20 000 тонн в год", year:"2021", active: true},
    {district: "СФО", name:"Разработка ESG-концепции и дорожной карты по внедрению для группы компаний (лесозаготовка и производство пиломатериалов)", year:"2021", active: true},
    {district: "СФО", name:"Разработка предварительного ТЭО проекта строительства лесопильного производства 250 000 м3/год пиломатериалов и 60 000 т/год пеллет", year:"2021", active: true},
    {district: "ПФО", name:"Маркетинговое исследование рынка (плитное производство)", year:"2021", active: true},
    {district: "ПФО", name:"Маркетинговое исследование рынка (пиломатериалы)", year:"2021", active: true},
    {district: "ПФО", name:"Сопровождение реализации стратегии развития компании, включая анализ рынка продукции, обеспечение сырьевой безопасности, анализ технических альтернатив перевооружения (целлюлозно-бумажное производство)", year:"2021", active: true},
]