import React, {Component} from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import {links} from "../../../libs/Main";
import logo from "../../../materials/zetta_logo_big.png";
import classes from "./NavBar.css";

const clsStyle = {
    a__nav_link: ["nav-link", classes.a__nav_link]
}

class NavBar extends Component {
    render() {
        return (
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="" height="24" className="d-inline-block align-text-top"/>
                        </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            {
                                links.map((link, index) => {
                                    return(
                                        <li key={index} className="nav-item">
                                            {
                                                <Link
                                                    className={clsStyle.a__nav_link.join(" ")}
                                                    to={link.to}
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-50}
                                                    duration= {400}
                                                >{link.label}</Link>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default NavBar;