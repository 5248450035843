import React, {Component} from 'react';
import {clsAllStyle} from "../../libs/Styles";
import Header from "../../components/Header/Header";
import classes from "./Management.css";
import img_ZauterA from "../../materials/imgs/team/ZauterA.jpg";
import img_IvanovK from "../../materials/imgs/team/IvanovK.jpg";

const clsStyle = {
    div__content: ["container"],
    p__text_name:[classes.p__text_name],
    p__text_regal:[classes.p__text_regal],
    img__photo:[classes.img__photo],
    div__col_sm:[classes.div__col_sm]
}

class Management extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name={"Руководство компании"} section={"management"}/>
                <div className={clsStyle.div__content.join(" ")}>
                    <div className="row">
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <div className="row">
                                <div className="col-4">
                                    <img className={clsStyle.img__photo.join(" ")} src={img_ZauterA} alt=""/>
                                </div>
                                <div className="col-8">
                                    <p className={clsStyle.p__text_name.join(" ")}>Андрей Заутер</p>
                                    <p>Эксперт в области стратегического управления, MBA</p>
                                    <p>Управляющий партнер, совладелец</p>
                                </div>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Более 20 лет опыта работы в производственных компаниях (Газпром, ТНК-BP, Мосэнерго, Т+) и ресурсных практиках крупнейших консалтинговых компаний (BCG, KPMG, Strategy Partners)</span></li>
                            </ul>
                            <p className={clsStyle.p__text_regal.join(" ")}>Эксперт в области разработки стратегии и оптимизации производства:</p>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Разработка Стратегии ТЭК Республики Казахстан до 2030 года</span></li>
                                <li><span>Разработка Стратегии ТЭК Республики Узбекистан до 2030 года</span></li>
                                <li><span>Разработка Стратегии развития лесного комплекса РФ до 2030 года</span></li>
                                <li><span>Разработка стратегий развития и оптимизации производств для производственных компаний: нефтегаз, энергетика, химическая промышленность, ЛПК</span></li>
                                <li><span>Преподавательская деятельность (стратегический менеджмент и маркетинг)</span></li>
                            </ul>
                        </div>
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <div className="row">
                                <div className="col-4">
                                    <img className={clsStyle.img__photo.join(" ")} src={img_IvanovK} alt=""/>
                                </div>
                                <div className="col-8">
                                    <p className={clsStyle.p__text_name.join(" ")}>Константин Иванов</p>
                                    <p>Эксперт в области технологии и реализации проектов, к.т.н.</p>
                                    <p>Генеральный директор, совладелец</p>
                                </div>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Опыт работы в ЛПК РФ более 15 лет, включая 10 лет в консалтинге и инжиниринге крупных инвестиционных проектов</span><br/><br/><br/></li>
                            </ul>
                            <p className={clsStyle.p__text_regal.join(" ")}>Эксперт в области технологии и управления проектами в ЛПК РФ:</p>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Руководство проектами для Группы «Илим», International Paper, Segezha Group, Архбум, Sveza, SFT Group, Карелия Палп, Группа Компаний «УЛК»</span></li>
                                <li><span>Образование — высшее техническое, кандидат технических наук</span></li>
                                <li><span>Разработка и реализация программы технического развития целлюлозно-бумажного комбината</span></li>
                                <li><span>Руководство проектной командой по проектированию объектов для инвестиционных проектов для лидеров ЛПК</span></li>
                                <li><span>Разработка технологической концепции и подбор оборудования для проекта строительства state-of-art лесопильного производства мощностью 500 тыс. м3 по входящему сырью</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Management;