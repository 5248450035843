import React, {Component} from 'react';
import classes from './Company.css';
import {clsAllStyle} from "../../libs/Styles";
import img_city from "../../materials/imgs/city.png"
import Header from "../../components/Header/Header";

const clsStyle = {
    div__content: [classes.div__content],
    div__text_info: [classes.div__text_info],
    div__img:[classes.div__img],
    img__content:[classes.img__content],
    ul__content: [classes.ul__content],
    p__name_company: [classes.p__name_company],
    span__color: [classes.span__color],
    span__weight: [classes.span__weight],
}

class Company extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name={"Кратко о компании"} section={"company"}/>
                <div className={clsStyle.div__content.join(" ")}>
                    <div className={clsStyle.div__text_info.join(" ")}>
                        <p className={clsStyle.p__name_company.join(" ")}>Zetta&nbsp;Consulting была образована в<br/><span>августе&nbsp;2021&nbsp;г.</span></p>
                        <ul className={clsStyle.ul__content.join(" ")}>
                            <li>Мы оказываем консультационные услуги в области стратегического и операционного консалтинга</li>
                            <li>Собственники компании – эксперты с опытом работы 15-20&nbsp;лет</li>
                            <li>С начала 2022 г. компания является членом СРО и имеет право осуществлять подготовку проектной и рабочей документации</li>
                            <li>Команда Zetta – 10&nbsp;человек</li>
                            <li>Офисы компании находятся в Москве и Санкт-Петербурге</li>
                            <li>Компания находится в партнерских отношениях с крупными консалтинговыми, технологическими и инжиниринговыми компаниями</li>
                        </ul>
                        <ul className={clsStyle.ul__content.join(" ")}>
                            <li>На январь 2022 года реализовано <span className={clsStyle.span__color.join(" ")}>15&nbsp;проектов</span></li>
                            <li>В стадии реализации - <span className={clsStyle.span__weight.join(" ")}>5&nbsp;проектов</span></li>
                        </ul>
                    </div>
                    <div className={clsStyle.div__img.join(" ")}>
                        <img className={clsStyle.img__content.join(" ")} src={img_city} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Company;