import React from 'react';
import classes from './Header.css';

const clsStyle = {
    div__header:[],
    p__header:[classes.p__header]
}

function Header(props) {
    return (
        <div>
            <section id={props.section}/>
            <p className={clsStyle.p__header.join(" ")}>{props.name}</p>
        </div>
    );
}

export default Header;