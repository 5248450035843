import React, {Component} from 'react';
import classes from './Competencies.css';
import {clsAllStyle} from "../../libs/Styles";
import Header from "../../components/Header/Header";
import img_lodding from "../../materials/imgs/Logging.png";
import img_cellulose from "../../materials/imgs/Cellulose.png";
import img_energy from "../../materials/imgs/Energy.png";
import img_digitalization from "../../materials/imgs/Digitalization.png";
import img_esgconcept from "../../materials/imgs/ESGconcept.png";
import img_oilgas from "../../materials/imgs/Oilgas_industry.png";

const clsStyle = {
    div__col_sm: [classes.div__col_sm],
    div__img_p_content: [classes.div__img_p_content],
}


class Competencies extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name="Компетенции компании" section={"competencies"}/>
                <div className="row">
                    <div className={clsStyle.div__col_sm.join(" ")}>
                        <div className={clsStyle.div__img_p_content.join(" ")}>
                            <img src={img_lodding} alt="Logging"/>
                            <p>Лесозаготовка и механическая переработка древесины</p>
                        </div>
                        <div className={clsStyle.div__img_p_content.join(" ")}>
                            <img src={img_energy} alt="Energy"/>
                            <p>Традиционная и возобновляемая энергетика, биотопливо</p>
                        </div>
                    </div>
                    <div className={clsStyle.div__col_sm.join(" ")}>
                        <div className={clsStyle.div__img_p_content.join(" ")}>
                            <img src={img_cellulose} alt="Cellulose"/>
                            <p>Целлюлозно-бумажная промышленность</p>
                        </div>
                        <div className={clsStyle.div__img_p_content.join(" ")}>
                            <img src={img_digitalization} alt="Digitalization"/>
                            <p>Цифровизация и цифровая трансформация</p>
                        </div>
                    </div>
                    <div className={clsStyle.div__col_sm.join(" ")}>
                        <div className={clsStyle.div__img_p_content.join(" ")}>
                            <img src={img_oilgas} alt="Oilgas_industry"/>
                            <p>Нефтегазовая и Нефтехимическая промышленность</p>
                        </div>
                        <div className={clsStyle.div__img_p_content.join(" ")}>
                            <img src={img_esgconcept} alt="ESGconcept"/>
                            <p>ESG-концепция и «зеленые» решения</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Competencies;