import React, {Component} from 'react';
import {clsAllStyle} from "../../libs/Styles";
import Header from "../../components/Header/Header";
import classes from "./Contacts.css";
import img_ZauterA from "../../materials/imgs/team/ZauterA.jpg";
import img_IvanovK from "../../materials/imgs/team/IvanovK.jpg";
import img_qr_ivanov from "../../materials/imgs/QR_Ivanov.png"
import img_qr_zauter from "../../materials/imgs/QR_Zauter.png"

const clsStyle = {
    div__content: ["container"],
    img__photo: [classes.img__photo],
    p__text_name:[classes.p__text_name],
    p__text_position:[classes.p__text_position],
    div__contact_info: ["col-sm", classes.div__contact_info],
    img__qr: [classes.img__qr],
    div__col_sm:[classes.div__col_sm]
}

class Contacts extends Component {
    render() {
        return (
            <div className={clsAllStyle.div__container.join(" ")}>
                <Header name={"Контактные данные"} section={"contacts"}/>
                <div className={clsStyle.div__content.join(" ")}>
                    <div className="row">
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <div className="row">
                                <div className="col-4">
                                    <img className={clsStyle.img__photo.join(" ")} src={img_ZauterA} alt=""/>
                                </div>
                                <div className="col-8">
                                    <p className={clsStyle.p__text_name.join(" ")}>Андрей Заутер</p>
                                    <p className={clsStyle.p__text_position.join(" ")}>Управляющий партнер, совладелец</p>
                                    <div className={clsStyle.div__contact_info.join(" ")}>
                                        <div>
                                            <p><a href="tel:+79854432875">+7(985)443-28-75</a></p>
                                            <p><a href="mailto:zauter@zettaconsulting.ru">zauter@zettaconsulting.ru</a></p>
                                        </div>
                                        <div><img className={clsStyle.img__qr.join(" ")} src={img_qr_zauter} alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Эксперт в области стратегического менеджмента</span></li>
                                <li><span>Более 20 лет опыта работы в произв. компаниях (Газпром, ТНК-BP, Мосэнерго, Т+) и ресурсных практиках крупнейших консалтинговых компаний (BCG, KPMG, Strategy Partners)</span></li>
                            </ul>
                        </div>
                        <div className={clsStyle.div__col_sm.join(" ")}>
                            <div className="row">
                                <div className="col-4">
                                    <img className={clsStyle.img__photo.join(" ")} src={img_IvanovK} alt=""/>
                                </div>
                                <div className="col-8">
                                    <p className={clsStyle.p__text_name.join(" ")}>Константин Иванов</p>
                                    <p className={clsStyle.p__text_position.join(" ")}>Генеральный директор, совладелец</p>
                                    <div className={clsStyle.div__contact_info.join(" ")}>
                                        <div>
                                            <p><a href="tel:+79642925091">+7(964)292-50-91</a></p>
                                            <p><a href="mailto:ivanov@zettaconsulting.ru">ivanov@zettaconsulting.ru</a></p>
                                        </div>
                                        <div><img className={clsStyle.img__qr.join(" ")} src={img_qr_ivanov} alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            <ul className={clsAllStyle.ul__content.join(" ")}>
                                <li><span>Эксперт в области технологии и реализации проектов в ЛПК РФ</span></li>
                                <li><span>Опыт работы в лесной промышленности более 15 лет, включая 10 лет в консалтинге и инжиниринге</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;